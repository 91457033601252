import request from '@/utils/request'

//催单订单详情列表数据

export const getRemOrder = (data)=>{
	return request({
		method:"GET",
		url:"factory/factoryReminder/selectReminderByStatusAndStoreId",
		params:data
	})
}

export const removeRemorder = (id)=>{
	return request({
		method:"GET",
		url:`factory/factoryReminder/deleteReminderByClothesNum/${id}`
	})
}

//催单表格查看按钮

export const reminderSee = (id)=>{
	return request({
		method:"GET",
		url:`factory/factoryReminder/selectOperationReminderById/${id}`
	})
}
//催单门店分页查询
export const remindersPage = (params)=>{
	return request({
		method:"GET",
		url: "/factory/factoryReminder/store/page",
		params
	})
}
//新增催单
export const addReminder = (data) => {
	return request({
		method: 'post',
		url:'/factory/factoryReminder/addReminder',
		data
	})
}
//查询可催单的订单
export const queryReminder = (id) => {
	return request({
		method: 'get',
		url:'/order/order/reminder/' + id
	})
}
//催单状态列表统计
export const reminderStatus = (params) => {
	return request({
		method: 'get',
		url:'/factory/factoryReminder/store/status/',
		params
	})
}
//催单详情
export const reminderDetail = (id) => {
	return request({
		method:'get',
		url:'/factory/factoryReminder/store/detail/' + id
	})
}
//再次催单
export const reminderAgain = (data) => {
	return request({
		method: 'post',
		url:'/factory/factoryReminder/again',
		data
	})
}
