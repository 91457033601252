<template>
    <div class="complaint">
        <div class="complain-header">
            <el-dialog
                    :show-close="true"
                    :visible="dialogVisible"
                    top="8vh"
                    width="60%"
                    :before-close="handleClose"
                    :close-on-click-modal="false"
                    append-to-body
            >
                <div slot="title" class="header-title">
                    <span class="icon-box"></span>
                    <span style="color:#000;font-size:16px"
                    >下方列表为当前订单下的衣物，请勾选需要催单的衣物（不可多选）</span
                    >
                </div>
                <div v-loading="reminderDialogLoading">
                    <div class="reminder">
                        <span class="verticalLine"></span>
                        <div style="padding-right: 10px">
              <span class="exclamatoryMark">
                <i class="el-icon-warning"></i>
              </span>
                        </div>
                        <div class="reminder-item">
                            <span style="color: #ff8800;font-size: 16px;">温馨提示：</span>
                            <span style="font-size: 14px; color: #000">
                只可针对在厂内已超时的衣物进行催单，特殊情况的可选择【加急催单】
              </span>
                        </div>
                    </div>
                    <div class="content">
                        <div class="content-item">
                            <span class="icon-box"></span>
                            <span style="color:#000">可正常催单衣物及附件（已超时）</span>
                        </div>
                        <div style="padding: 0 20px">
                            <el-table
                                    ref="multipleTable"
                                    :data="reminderData.normalList"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                    height="180px"
                                    @selection-change="selectionChange"
                            >
                                <el-table-column
                                        label-class-name="DisabledSelection"
                                        width="70px"
                                        type="selection"
                                        header-align="center"
                                        align="center"
                                >
                                </el-table-column>
                                <el-table-column
                                        v-for="item in tHeader"
                                        align="center"
                                        :key="item.value"
                                        :label="item.value"
                                        :prop="item.props"
                                />
                            </el-table>
                        </div>
                    </div>
                    <div class="content">
                        <div class="content-item">
                            <span class="icon-box"></span>
                            <span style="color:#000">可加急催单衣物及附件（未超时）</span>
                        </div>
                        <div style="padding: 0 20px">
                            <el-table
                                    ref="urgentReminder"
                                    :data="reminderData.expeditedList"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                    height="180px"
                                    @selection-change="urgentReminderClick"
                            >
                                <el-table-column
                                        label-class-name="DisabledSelection"
                                        width="70px"
                                        type="selection"
                                        header-align="center"
                                        align="center"
                                >
                                </el-table-column>
                                <el-table-column
                                        v-for="item in tHeader"
                                        align="center"
                                        :key="item.value"
                                        :label="item.value"
                                        :prop="item.props"
                                />
                            </el-table>
                        </div>
                    </div>
                    <div class="content">
                        <div class="content-item">
                            <span class="icon-box"></span>
                            <span style="color:#000"
                            >不可催单衣物及附件（未入厂、已出厂、催单中或售后中的衣物不可进行催单）</span
                            >
                        </div>
                        <div style="padding: 0 20px">
                            <el-table
                                    :data="reminderData.cannotList"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                    height="180px"
                            >
                                <el-table-column label="" width="70px"></el-table-column>
                                <el-table-column
                                        v-for="item in tHeader"
                                        align="center"
                                        :key="item.value"
                                        :label="item.value"
                                        :prop="item.props"
                                />
                            </el-table>
                        </div>
                    </div>
                    <div class="footer">
                        <el-button
                                @click="regularReminder(1)"
                                :disabled="selectObj == null"
                                :class="[selectObj != null ? 'normalColor' : 'display']"
                                style="width:150px"
                        >常规催单
                        </el-button
                        >
                        <el-button
                                :disabled="urgent == null || reminderData.remainingExpedited == 0"
                                :class="[
                urgent != null && reminderData.remainingExpedited > 0
                  ? 'urgentColor'
                  : 'display',
              ]"
                                style="width:150px"
                                @click="regularReminder(2)"
                        >加急催单
                        </el-button>
                        <span
                                :class="[
                urgent != null && reminderData.remainingExpedited > 0
                  ? 'surplus'
                  : 'surplusDisable',
              ]"
                        >剩余{{ reminderData.remainingExpedited }}次</span
                        >
                    </div>
                </div>
            </el-dialog>
            <el-dialog
                    :visible.sync="appealDialogVisible"
                    width="45%"
                    :show-close="false"
                    :close-on-click-modal="false"
                    append-to-body
            >
                <div slot="title" class="header-title">
                    <span class="icon-box"></span>
                    <span style="color:#000;font-size:16px">请填写</span>
                    <el-divider style="margin:0"/>
                </div>
                <el-form
                        :model="ruleForm"
                        :rules="rules"
                        ref="ruleForm"
                        label-width="30%"
                        class="demo-ruleForm"
                >
                    <el-form-item label="催单诉求：" prop="reminderAppeal">
                        <el-select v-model="ruleForm.reminderAppeal">
                            <el-option
                                    v-for="(item, index) in demandAppeal"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value"
                            ></el-option>
                        </el-select>
                        <div v-if="ruleForm.reminderAppeal == 1">
              <span
              >当选择【今日回店】后，工厂将无视衣物清洗效果（事故、售后
                除外），一律将衣物找出后立即安排出厂，到店后的衣物可能会
                略有瑕疵或有未洗净污渍。</span
              >
                            <p style="color:red;margin:0">
                                请谨慎选择【今日回店】！（非特殊情况，建议选择【尽快回店】）
                            </p>
                        </div>
                    </el-form-item>

                    <el-form-item label="反馈内容：" prop="storeExplain">
                        <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                v-model="ruleForm.storeExplain"
                                maxlength="200"
                                :rows="4"
                                style="width:80%"
                                show-word-limit
                        >
                        </el-input>
                    </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
          <el-button
                  :loading="loading"
                  type="primary"
                  @click="submitForm('ruleForm')"
                  style="background: #074973;color:#fff;border:1px solid #074973"
          >确定催单</el-button
          >
          <el-button @click="returnClick" style="width:98px">返回</el-button>
        </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {addReminder, reminderAgain} from "@/api/factory/reminderOrder";

export default {
    name: "",
    components: {},
    props: {
        dialogVisible: {
            type: Boolean,
            default: false,
        },
        addLoading: {
            type: Boolean,
            default: false,
        },
        reminderDialogLoading: {
            type: Boolean,
            default: false,
        },
        springFrame: {
            type: Boolean,
            default: false,
        },
        reminderData: {
            type: Object,
            default: () => {
            },
        },
        reminderList: {
            type: Object,
            default: () => {
            },
        },
    },
    data() {
        return {
            tHeader: [
                {
                    props: "outClothesNum",
                    value: "衣物条形码",
                },
                {
                    props: "clothesStatusDesc",
                    value: "衣物状态",
                },
                {
                    props: "clothesName",
                    value: "衣物名称",
                },
                {
                    props: "clothesColor",
                    value: "衣物颜色",
                },
                {
                    props: "reminderStatusDesc",
                    value: "催单状态",
                },
                {
                    props: "afterSaleStatusDesc",
                    value: "售后状态",
                },
                {
                    props: "rewashTypeDesc",
                    value: "返洗类型",
                },
            ],
            selectObj: null, //正常
            urgent: null, //加急
            appealDialogVisible: false,
            loading: false,
            demandAppeal: [
                {
                    label: "今日回店",
                    value: "1",
                },
                {
                    label: "尽快回店",
                    value: "2",
                },
            ],
            ruleForm: {
                reminderAppeal: "2",
                storeExplain: "",
                reminderType: "",
                outClothesNum: "",
            },
            rules: {
                reminderAppeal: [
                    {required: true, message: "催单诉求不能为空", trigger: "change"},
                ],
                storeExplain: [
                    {required: true, message: "催单内容不能为空", trigger: "change"},
                    {
                        pattern: /^[^\s]+[\s]*.*$/,
                        trigger: "blur",
                        message: "不能只输入空格",
                    },
                ],
            },
        };
    },
    watch: {
        springFrame(val) {
            this.appealDialogVisible = val;
            this.ruleForm = {
                reminderAppeal: "2",
                storeExplain: "",
                reminderType: "",
                outClothesNum: "",
            };
            if (this.$refs.ruleForm != undefined) {
                this.$refs.ruleForm.resetFields();
            }
        },
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    if (Object.keys(this.reminderList).length != 0) {
                        let data = {
                            reminderId: this.reminderList.reminderId,
                            reminderAppeal: this.ruleForm.reminderAppeal,
                            storeExplain: this.ruleForm.storeExplain,
                            outClothesNum: this.reminderList.outClothesNum,
                        };
                        reminderAgain(data)
                            .then((res) => {
                                if (res.data.code == 0) {
                                    this.$message.success(res.data.msg);
                                    this.loading = false;
                                    this.$emit("reminderSucceeded");
                                    this.returnClick();
                                } else {
                                    this.$message.error(res.data.msg);
                                    this.loading = false;
                                }
                            })
                            .catch(() => {
                                this.loading = false;
                            });
                    } else {
                        if (this.selectObj != null) {
                            this.ruleForm.outClothesNum = this.selectObj.outClothesNum;
                        } else {
                            this.ruleForm.outClothesNum = this.urgent.outClothesNum;
                        }
                        addReminder(this.ruleForm)
                            .then((res) => {
                                if (res.data.code == 0) {
                                    this.$message.success(res.data.msg);
                                    this.appealDialogVisible = false;
                                    this.loading = false;
                                    this.$emit("refreshReminderList");
                                } else {
                                    this.$message.error(res.data.msg);
                                    this.loading = false;
                                }
                            })
                            .catch(() => {
                                this.loading = false;
                            });
                    }
                } else {
                    return false;
                }
            });
        },
        //返回
        returnClick() {
            this.appealDialogVisible = false;
            this.$emit("appealDialogVisible");
        },
        //当选择项发生变化时
        selectionChange(val) {
            this.selectObj = null;
            if (val.length > 1) {
                //当选中两个以上的选项时
                this.$refs.multipleTable.clearSelection(); //清空所有
                //再把第一次选中的勾选上
                this.$refs.multipleTable.toggleRowSelection(val[val.length - 1]);
                //selectObj 用来保存勾选的那一项内容
                this.selectObj = val[val.length - 1];
            } else if (val.length == 1) {
                this.$refs.urgentReminder.clearSelection(); //清空所有
                this.selectObj = val[0];
            }
        },
        urgentReminderClick(val) {
            this.urgent = null;
            if (val.length > 1) {
                this.$refs.urgentReminder.clearSelection(); //清空所有
                this.$refs.urgentReminder.toggleRowSelection(val[val.length - 1]);
                this.ruleForm.outClothesNum = "";
                this.urgent = val[val.length - 1];
            } else if (val.length == 1) {
                this.$refs.multipleTable.clearSelection(); //清空所有
                this.urgent = val[0];
            }
        },
        //常规催单
        regularReminder(val) {
            this.ruleForm.reminderType = val;
            console.log(this.ruleForm.reminderType, val);
            this.appealDialogVisible = true;
            if (this.$refs.ruleForm != undefined) {
                this.$refs.ruleForm.resetFields();
            }
            console.log(11);
        },
        handleClose() {
            this.$emit("closeDialog", 1);
        },
    },
};
</script>

<style lang="scss" scoped>
.complaint-container {
  padding: 20px;
  background-color: #fff;
}

.icon-box {
  display: inline-block;
  background: #3370FF;
  width: 4px;
  height: 20px;
  border-radius: 2px;
  vertical-align: bottom;
  margin-right: 5px;
}

.verticalLine {
  display: inline-block;
  background: #ff8800;
  width: 3px;
  height: 60px;
  border-radius: 2px;
  vertical-align: bottom;
  margin-right: 5px;
}

.reminder {
  display: flex;
  flex-direction: row;
  background: #fff3e5;

  .exclamatoryMark {
    display: flex;
    text-align: center;
    padding: 20px 0 0 15px;
    border-radius: 50%;

    i {
      font-size: 25px;
      color: rgb(255, 136, 0);
      position: relative;
      top: -8px;
    }
  }

  .reminder-item {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }
}

.complain-header {
  ::v-deep .el-dialog__header {
    margin: 0;
  }
}

.normalColor {
  background-color: #ff8800;
  color: #fff;
  border: 1px solid #ff8800;
}

.urgentColor {
  background-color: #860404;
  color: #fff;
  border: 1px solid #860404;
}

.disable {
  color: #c0c4cc;
  cursor: not-allowed;
  background-image: none;
  background-color: #fff;
  border-color: #ebeef5;
}

.surplus {
  color: #fff;
  font-size: 12px;
  margin-left: -45px;
}

.surplusDisable {
  color: #c0c4cc;
  font-size: 12px;
  margin-left: -45px;
}

// ::v-deep .el-dialog__header {
//   margin: 20px;
// }
::v-deep .el-dialog__body {
  padding: 0px 20px;
}

.content {
  overflow: auto;
  margin: 15px 0;
  padding: 10px 0;
  background: #eff1f2;

  .content-item {
    // padding: 10px 0;
  }
}

.footer {
  display: flex;
  justify-content: center;
  padding: 15px 0;
}

.dialog-footer {
  display: flex;
  justify-content: center;
}

/*表格表头全选*/
.el-table /deep/ .DisabledSelection .cell .el-checkbox__inner {
  margin-left: -30px;
  position: relative;
  display: none;
}

.el-table /deep/ .DisabledSelection .cell:before {
  content: "勾选";
  position: absolute;
  right: 20px;
}

::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
}

/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent !important;
  border: 0; //去除表格
}

/*去除底边框*/
::v-deep.el-table td.el-table__cell {
  border: 0;
}

::v-deep.el-table th.el-table__cell.is-leaf {
  border: 0;
}

::v-deep .el-divider--horizontal {
  margin: 10px 0;
}
</style>
